import { Link } from "react-router-dom";

function Stores() {
  return (
    <div className="flex flex-row space-x-4 justify-center">
      <Link
        to="https://apps.apple.com/tr/app/tezene/id1495218175?l=tr"
        className="text-orange-600 hover:underline cursor-pointer"
      >
        <img
          src="/download-app-store.png"
          alt="App Store'dan indirin"
          className="mx-auto h-10"
        />
      </Link>
      <Link
        to="https://play.google.com/store/apps/details?id=com.refyazilim.app.baglama"
        className="text-orange-600 hover:underline cursor-pointer"
      >
        <img
          src="/download-play-store.png"
          alt="Play Store'dan indirin"
          className="mx-auto h-10"
        />
      </Link>
    </div>
  );
}

export default Stores;
