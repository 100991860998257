import React from "react";
import "./Terms.css";

function Terms() {
  return (
    <div class="terms-container">
      <h1>TEZENE KULLANICI SÖZLEŞMESİ</h1>
      <h2>Madde 1 - Taraflar</h2>
      İşbu Kullanıcı Sözleşmesi ("Sözleşme"); bir tarafta
      <b>Ref Yazılım ve Bilişim Teknolojileri Ticaret Limited Şirketi</b>{" "}
      tarafından işletilen Tezene mobil iOS ve Andriod uygulamaları (bundan
      böyle topluca "<b>Tezene</b>" olarak anılacaktır); ile diğer tarafta
      bilgileri Tezene mobil iOS veya Android uygulamaları üzerinden belirtilen
      tüm tek kişi ve tüzel kişi üyeler ("<b>Kullanıcı</b>") arasında aşağıdaki
      hüküm ve şartlar çerçevesinde akdedilmektedir.
      <br />
      <h2>Madde 2 - Sözleşme'nin Konusu</h2>
      İsbu Sözleşme, Kullanıcı'nın Tezene mobil iOS veya Android uygulamaları
      tarafından sunulan kullanıcılarına bağlama öğretme hizmetlerinden
      yararlanmasını sağlamak amacı ile düzenlenmiş olup, anılan ilişki
      kapsamında tarafların karşılıklı hak ve yükümlülüklerini tespit
      etmektedir.
      <h2>Madde 3 - Kayıt Oluşturma</h2>
      <b>3.1.</b> Kullanıcı, e-posta ve belirlediği şifre ile Tezene'ye giriş
      yapabilecektir. Aynı zamanda Kullanıcı’ya Tezene'nin girişi ekranında
      "Şifremi Unuttum" ve "Hesap Oluştur" seçenekleri sunulacaktır. Kullanıcı,
      Tezene'nin giriş ekranında hatalı bir giriş yapması halinde ilgili ekrana
      uyarı mesajı gelecektir.
      <br />
      <b>3.2.</b> Kullanıcı’nın Tezene'nin giriş ekranından "Şifremi Unuttum"
      seçeneğini tıklaması halinde, açılan Tezene'nin Şifre Yenileme ekranından
      e-posta adresi istenecek ve "Gönder" butonuna basıldığı takdirde
      Kullanıcı‘nın şifresini yenilemesi için daha önceden Tezene'ye girmiş
      olduğu e-posta adresine bir link gönderilecektir. Kullanıcı, e-posta
      adresine gönderilen linke tıklayarak şifre değiştirme işlemini
      gerçekleştirmiş olacaktır.
      <br />
      <b>3.3.</b> Kullanıcı, Tezene'nin giriş ekranından "Hesap Oluştur"
      seçeneğini tıklaması halinde, açılan Tezene'nin Hesap Oluştur ekranın
      kayıt formundan E-posta ve kendine özel bir şifre bilgilerini girerek
      kayıt oluşturabilecek. Tezene'nin Hesap Oluştur kayıt formunda tüm
      bilgiler Kullanıcı tarafından girilmesi zorunludur. Kullanıcı, Tezene'nin
      Hesap Oluştur ekranında hatalı bir giriş yapması halinde ilgili ekrana
      uyarı mesajı gelecektir.
      <br />
      <h2>Madde 4 - Kullanıcı'nın Hak ve Yükümlülükleri</h2>
      <b>4.1.</b> Kullanıcı, Tezene'nin Hesap Oluştur ekranında kullanacağı
      e-posta ve şifre ile sadece bir tek kişi veya tüzel kişi için kayıt işlem
      yapma hakkına sahiptir. Diğer tek kişi veya tüzel kişi üyeler Tezene'den
      yararlanmak için ayrıca kayıt işleminin yapılması ve başka bir e-posta ve
      şifrenin alınması ile gerçekleştirilecektir.
      <br />
      <b>4.2.</b> Kullanıcı, Tezene'nin Hesap Oluştur ekranındaki kayıt
      oluşturma işleminde vereceği bilgilerin hukuka uygun ve doğru olmasından
      tamamen sorumlu olduğunu ve bu bilgilerin hatalı veya noksan olmasından
      doğacak zararlardan dolayı da Tezene’nin hiçbir sebeple sorumlu olmadığını
      kabul, beyan, ve taahhüt etmektedir. Hatalı veya noksan bilgilerin sıkıntı
      veya problem oluşturduğu şartlarda Tezene, Kullanıcı’nın kaydını sona
      erdirme hakkına sahiptir.
      <br />
      <b>4.3.</b> Kullanıcı'nın Hesap Oluştur ekranında oluşturduğu Şifre,
      sadece Kullanıcı tarafından bilinir ve şifrenin seçimi ve korunmasının
      sorumluluğu tamamen Kullanıcı’ya ait olacaktır. Tezene, şifre
      kullanımından doğacak problemlerden sorumlu değildir.
      <br />
      <b>4.4.</b> Kullanıcı, Tezene'den elde ettiği hizmet ve bilgileri olumsuz
      kullanarak aşağıda yer alan yasaklanmış eylemlerde bulunmayacağını kabul,
      beyan ve taahhüt etmektedir;
      <br />
      <ul>
        <li>Hakaret ve iftira edici davranışta bulunmak,</li>
        <li>
          Yasadışı faaliyetleri teşvik edici faaliyette bulunmak ve içerik
          yayınlamak,
        </li>
        <li>
          Yürürlükteki ve ileride yürürlüğe girecek her türlü mevzuat kapsamında
          yasak olan her türlü faaliyette bulunmak.
        </li>
      </ul>
      <br />
      <b>4.5.</b> Kullanıcı’nın kendi yapmış olduğu App Store ve Google Play
      aboneliği, kullanım, ve gizlilik koşullarından Tezene sorumlu değildir.
      <br />
      <b>4.6.</b> 4.6. Kullanıcı, Tezene üzerinden sunulan hizmetlerin ve
      kullanılan yazılımların telif hakkının Tezene'ye ve/veya Tezene'ye lisans
      veren veya içerik sağlayan kişilere ait olduğunu, fikri mülkiyet hukuku
      kapsamında korunduğunu; ihlali durumunda Kullanıcı’nın sorumlu olacağını;
      bu yazılımları ya da servisleri hiçbir şekilde izinsiz çoğaltıp
      dağıtmayacağını, yayınlamayacağını, pazarlamayacağını kabul, beyan, ve
      taahhüt etmektedir.
      <br />
      <b>4.7.</b> Kullanıcı, Tezene’yi başka uygulamalar ya da üçüncü kişilerle
      ihtilaflı duruma getirecek herhangi bir yazılım veya materyal
      bulunduramayacağını, paylaşamayacağını ve herhangi bir şekilde bir hukuka
      aykırı durum doğarsa bunun hukuki ve cezai sorumlulukların tümünü üzerine
      aldığını kabul, beyan, ve taahhüt etmektedir.
      <br />
      <h2>Madde 5 - Tezene'nin Hak ve Yükümlülükleri</h2>
      <b>5.1.</b> Tezene site ve uygulamalarda sunulan hizmetleri ve içerikleri
      her zaman değiştirebilme ve/veya silme hakkını saklı tutmaktadır. Tezene
      bu hakkını hiçbir bildirimde bulunmadan ve önel vermeden kullanabilir.
      Tezene’nin Kullanıcı’lardan talep ettiği değişiklik ve/veya düzeltme
      istekleri Kullanıcı tarafından doğrudan yapılabileceği gibi, gerekli
      görüldüğü takdirde, Tezene tarafından da doğrudan yapılabilir. Tezene
      tarafından talep edilen değişiklik ve/veya düzeltme taleplerinin,
      Kullanıcı'lar tarafından zamanında yerine getirilmemesi sebebiyle doğan
      veya doğabilecek zararlar hukuki ve cezai sorumluluklar tamamen
      Kullanıcı'lara aittir. Tezene dilediği zamanda herhangi bir gerekçe
      göstermeksizin, Kullanıcı'dan üyelik bilgilerinin doğrulanması için
      birtakım ek bilgi ve evrak isteme hak ve yetkisine sahiptir. Kullanıcı
      işbu bilgi evrakları derhal Tezene’ye sunmakla yükümlüdür. Aksi halde,
      Tezene işbu evrakların sunulma anına kadar, Kullanıcı'nın üyeliğini pasif
      hale getirme ve uygulamayı kullanmasını engelleme hak ve yetkisine
      sahiptir.
      <br />
      <b>5.2.</b> İşlem kolaylığı veya çeşitli sebepler nedeniyle, site ve
      uygulamalar içerisinde yalnızca Tezene tarafından, doğrudan Tezene
      kontrolünde olmayan birtakım internet sitelerine veya içeriklere linkler
      verilebilir. Tezene üzerinde linkler vasıtasıyla erişilen portallar veya
      internet sitelerinde sunulan hizmetler, ürünler veya bunların içeriği
      hakkında Tezene’nin herhangi bir sorumluluğu bulunmamaktadır.
      <br />
      <b>5.3.</b> Tezene bağlama çalmayı öğrenmek isteyen Kullanıcı'larına
      yönelik çeşitli hizmetleri ücretli ve ücretsiz olarak sağlamaktadır.
      Tezene hizmet koşullarında değişiklik yapma hakkına sahiptir. Bu konuda,
      Kullanıcı'lara bildirimin yapılmasından itibaren, söz konusu hizmetlerden
      değişiklikten önce faydalanan Kullanıcı'lar da değişiklikler sonrası yeni
      şart ve koşullar ile hizmetlerden faydalanacaktır.
      <br />
      <b>5.4.</b> Tezene sistemin çalışmasını geçici bir süre askıya alabileceği
      gibi tamamen de durdurabilecektir. Kullanıcı'lar, web sitesinin veya
      uygulamanın kullanımına ilişkin olarak Tezene’den sistemin durdurulması
      veya askıya alınması nedeniyle herhangi bir talepte bulunmayacaklarını
      kabul, beyan ve taahhüt ederler.
      <br />
      <h2>Madde 6 - Hizmetler</h2>
      Tezene site ve uygulamalarında, Kullanıcı'lara işbu Sözleşme kapsamında
      içeriği belirlenen hizmetleri sağlayacaktır. Tezene tarafından verilen
      hizmetlerin temelinde; Kullanıcı'lara Tezene'ye eklenmiş türküler ve
      notaların bağlama üzerindeki yerlerinin öğretmek bulunmaktadır.
      <br />
      <b>6.1.</b> Kullanıcı bilgileri Tezene tarafından herhangi bir sınırlama
      olmaksızın kullanılabilir. Bu bilgilerin korunmasına ve gizliliğine
      ilişkin koşullar işbu Sözleşme’nin 8. ve 9. maddeleri kapsamında
      detaylandırılmıştır.
      <br />
      <b>6.2.</b> Kullanıcı, Tezene'ye bildirmiş olduğu tüm bilgilerinin doğru
      olmasını sağlamakla yükümlüdür.
      <br />
      <h2 id="privacy">Madde 7 - Gizlilik Politikası</h2>
      Tezene site ve uygulamalarda, Kullanıcı'lar ile ilgili bilgileri işbu
      Sözleşme’nin kapsamı dışında kalan ve işbu Sözleşme’nin 8. ve 9. maddeleri
      kapsamında yer alan koşullar dâhilinde üçüncü kişilere açıklayabilir veya
      üçüncü kişilere ilişkin çalışmalarda kullanılabilir.
      <br />
      <h2>Madde 8 - Kişisel Verilerin Korunması ve Gizlilik Politikası</h2>
      Kullanıcı'ların kişisel verilerinin Tezene site ve uygulamalarında hangi
      amaçlarla işlendiğine dair detaylı bilgiye aşağıdaki Aydınlatma Metni'nden
      ulaşabilmektedir. İşbu Aydınlatma Metni uyarınca; Türkiye’de yerleşik,
      İstanbul Ataşehir'de mukim, Tezene; veri sorumlusu sıfatıyla,
      Kullanıcı'larıyla olan ilişkisi kapsamında, kişisel verilerin,
      işlenmelerini gerektiren amaç çerçevesinde ve bu amaç ile bağlantılı,
      sınırlı ve ölçülü şekilde, Kullanıcı tarafından bildirildiği şekliyle
      doğruluğunu ve en güncel halini koruyarak, kaydedeceğini, depolayacağını,
      muhafaza edeceğini, yeniden düzenleyeceğini, kanunen bu kişisel verileri
      talep etmeye yetkili olan kurumlar ile paylaşacağını, yurtiçi veya
      yurtdışı üçüncü kişilere aktaracağını, devredeceğini,
      sınıflandırılabileceğini ve 6698 sayılı Kişisel Verileri Koruma Kanunu’nda
      (Bundan sonra “KVKK” olarak anılacaktır.) sayılan sair şekillerde
      işleyebileceğini bildirmektedir. Kullanıcı'ların Tezene site ve
      uygulamaları üzerinden paylaştığı bilgiler zaman zaman özel nitelikli
      kişisel veriler içerebilmektedir. KVKK kapsamında; ırk, etnik köken,
      siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlara, kılık ve
      kıyafete, dernek, vakıf ya da sendika üyeliklerine, sağlığa, cinsel
      hayata, varsa ceza mahkûmiyetine ve güvenlik tedbirlerine ilişkin veriler
      ile biyometrik ve genetik veriler Kullanıcı'ların özel nitelikli verileri
      kapsamındadır.
      <br />
      <h3>8.1. Veri Sorumlusu Sıfatıyla İşlenen Kişisel Veriler</h3>
      Tezene veri sorumlusu sıfatıyla aşağıdaki verileri, uygulamaya kayıt
      esnasında toplayarak işbu Sözleşme ve Sözleşme’nin ifasıyla doğrudan
      ilgili olmaları nedeniyle işlemektedir:
      <br />
      <ul>
        <li>Bireysel tanımlama bilgileri ad, soyad, e-posta adresi</li>
        <li>
          Kullanıcı'nın kullandığı mobil aygıtın işletim sistemi ve sürümünün
          versiyonu bilgisi
        </li>
        <li>
          Kullanıcı'nın kullandığı mobil aygıttan edinilen konum bilgileri
        </li>
        <li>Uygulama içindeki iletişim bölümünden iletilen yorumlar</li>
        <li>Kullanılan cihaz dili, saat dilimi</li>
      </ul>
      <h3>8.2. Kişisel Verilerin İşlenme Amaçları</h3>
      Tezene kişisel verileri aşağıda belirtilen amaçlar doğrultusunda
      işlemektedir:
      <br />
      <ul>
        <li>
          Kullanıcı'nın kimliğini doğrulamak, Kullanıcı'nın vermiş olduğu
          e-posta adresine doğrulama kodu gönderme vasıtasıyla kullanılabilir
        </li>
        <li>Üyelik desteği sağlamak ve olası sorunları gidermek</li>
        <li>
          Tezene işbu Sözleşme uyarınca, Kullanıcı'ların kendisinde kayıtlı
          e-posta adreslerine bilgilendirme mailleri gönderilmesini kabul etmiş
          sayılacaktır. Kullanıcı mail almaktan iletisim@tezene.app adresine,
          "İletişim İzni İptali" konulu bir mail atarak vazgeçebilir.
        </li>
        <li>Kullanıcı'ları yeni içeriklerden haberdar etmek</li>
      </ul>
      <h3>8.3. Kişisel Verilerin Aktarılması ve Veri Güvenliği</h3>
      Kullanıcı’nın verileri Tezene’in güvenli sunucularında saklanmaktadır.
      Sunucular Avrupa sınırları içerisinde bulunmaktadır. İşbu Sözleşme’nin
      onaylanmasıyla beraber üyeler verilerin Türkiye dışındaki sunucularda
      bulundurulmasına açık izin vermiş sayılır. Uygulamaya Kullanıcı'nın
      belirlemiş olduğu şifre ile erişim sağlanabilir ve şifreler Tezene’nin
      sisteminde geri çevrilemeyecek şekilde kodlanmış olarak tutulur. Şifre ve
      kullanıcı bilgilerini güvende tutmak ve paylaşmak Kullanıcı’nın
      sorumluluğundadır. Ancak verilerin alınmasının halka açık internet
      üzerinden yapılmasının %100 güvenli olduğunun garantisi verilemez. Tezene,
      Kullanıcı'nın verilerini korumak için şifrelemiş SSL bağlantı
      kullanmaktadır. Fakat veri aktarımı sırasında, Kullanıcı'nın internet
      bağlantısına yapılan saldırıların riski Kullanıcı'nın üzerindedir.
      Kullanıcı'nın verilerinin Tezene’nin sunucularına ulaştıktan sonra
      korunması ile ilgili her türlü güvenlik önlemi ise Tezenen tarafından
      alınır. Tezenen yukarıda belirtilen istisna dışında, kişisel verileri ya
      da iletişim içeriğini hukuken gerekli veya yükümlü olmadıkça (mahkeme
      kararları hariç olmak üzere), Kullanıcı'nın açık izni olmaksızın satmaz,
      kiralamaz, pazarlamaz veya başka bir suretle üçüncü kişilere aktarmaz.
      <br />
      <h3>8.4. Kişisel Verilerin Korunmasına İlişkin Haklar</h3>
      Kullanıcı'ların kişisel verilerinin korunmasına ilişkin hakları aşağıda
      belirtilmiştir:
      <br />
      <ul>
        <li>
          Kullanıcı'nın kişisel verilerinin işlenip işlenmediğini öğrenmek
        </li>
        <li>
          Kullanıcı'nın kişisel verileri işlenmişse buna ilişkin bilgi talep
          etmek
        </li>
        <li>
          Kullanıcı'nın kişisel verilerinin hangi amaçla işlendiğini ve bu
          amaçlara uygun kullanıp kullanmadığını öğrenmek
        </li>
        <li>
          Kullanıcı'nın kişisel verilerinin yurt içinde veya yurt dışında
          aktarıldığı üçüncü kişileri bilmek
        </li>
        <li>
          Kullanıcı'nın kişisel verilerinin silinmesini veya yok edilmesini
          talep etmek
        </li>
        <li>
          Kullanıcı'nın düzeltme, silme ve yok etme taleplerinin kişisel
          verilerinin aktarıldığı üçüncü kişilere bildirilmesini istemek,
          işlenen verilerinin münhasıran otomatik sistemler vasıtasıyla analiz
          edilmesi suretiyle Kullanıcı'nın şahsı aleyhine bir sonucun ortaya
          çıkmasına itiraz etmek
        </li>
        <li>
          Kullanıcı'nın kişisel verilerinin kanuna aykırı olarak işlenmesi
          sebebiyle zarara uğraması halinde zararın giderilmesini talep etmek
        </li>
      </ul>
      Kullanıcı kişisel verilerinin korunmasına ilişkin her türlü talebini
      iletisim@tezene.app adresine iletebilir. Kullanıcı'nın talebi Tezene
      tarafından titizlikle değerlendirilecek ve Kullanıcı'ya en kısa zamanda
      dönüş yapılacaktır.
      <br />
      <h2>Madde 9 - Diğer Hükümler</h2>
      <h3>9.1. Sözleşme Değişiklikleri</h3>
      Tezene hizmetlerin sürekliliğini sağlama, ileride doğacak teknik
      zaruretler veya mevzuata uyum sağlanması amacıyla Sözleşme’yi kendi
      takdirine bağlı ve tek taraflı olarak değiştirme ya da tadil etme hakkına
      sahiptir. Tezene, herhangi bir değişiklik olması halinde güncel kullanım
      şartlarını aynı link altında yeni tarih güncellemesi ile web sitesinde
      yayınlayacak, gerek görmesi halinde e-posta veya mobil anlık bildirim ile
      kullanıcılarına bildirecektir. İşbu Sözleşme’nin değişen hükümleri, ilan
      edildikleri tarihte geçerlilik kazanacak, geri kalan hükümler aynen
      yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. Site ve
      uygulamaların veya hizmetlerinin kullanımı o andan itibaren yenilenmiş
      Sözleşme uyarınca gerçekleşir, Kullanıcı'nın tek taraflı beyanları ile
      değiştirilemez.
      <br />
      <h3>9.2. Mücbir Sebepler</h3>
      Hukuken mücbir sebep sayılan tüm durumlarda, Tezene işbu Sözleşme ile
      belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya hiç
      ifa etmeme nedeniyle yükümlü değildir. Site ve uygulamaların Tezene’nin
      iradesi dışında, kusuru olmaksızın üçüncü kişiler tarafından ele
      geçirilmesi durumunda, meydana gelecek tüm olumsuzluklarda Tezene hiçbir
      suretle sorumlu olmayacaktır. Bu ve bunun gibi durumlar, Tezene için
      gecikme, eksik ifa etme veya ifa etmeme veya temerrüt addedilmeyecek veya
      bu durumlar için Tezene’den herhangi bir nam altında tazminat talep
      edilemeyecektir. Mücbir sebep terimi, isyan, halk ayaklanması, savaş, grev
      ve lokavt, doğal afet, hükümet tehditleri, kaza, sabotaj, patlama, terör
      saldırısı, iletişim sorunları, altyapı ve internet arızaları, elektrik
      kesintisi ve kötü hava koşulları, Tezene’nin gerekli bilgi güvenliği
      önlemleri almasına karşın Site ve uygulamaya veya sisteme yapılan
      saldırılar da dahil ve fakat bunlarla sınırlı olmamak kaydıyla, Tezene’nin
      makul kontrolü haricinde gelişen ve Tezene’nin gerekli özeni göstermesine
      rağmen önleyemediği, kaçınılamayacak olaylar olarak yorumlanacaktır.
      <br />
      <h3>9.3. Uygulanacak Hukuk ve Yetki</h3>
      İşbu Sözleşme’nin uygulanmasında, yorumlanmasında ve işbu Sözleşme
      dâhilinde doğan hukuki ilişkilerin yönetiminde yabancılık unsuru bulunması
      durumunda Türk hukuku uygulanacaktır. İşbu Sözleşme’den dolayı doğan ve
      doğabilecek her türlü ihtilafta “İstanbul Anadolu Adliyesi Mahkemeleri” ve
      “İcra Daireleri” yetkilidir.
      <br />
      <h3>9.4. Tezene Kayıtlarının Geçerliliği</h3>
      Kullanıcı, işbu Sözleşme’den doğabilecek ihtilaflarda Tezene’nin kendi
      veri tabanında, sunucularında tutulan elektronik ve sistem kayıtlarının,
      ticari kayıtlarının, defter kayıtlarının mikrofilm, mikrofiş ve bilgisayar
      kayıtlarının muteber bağlayıcı, kesin ve münhasır delil teşkil edeceğini,
      bu maddenin 6100 sayılı Hukuk Muhakemeleri Kanunu’nun 287. maddesi
      anlamında “Delil Sözleşmesi” niteliğinde olduğunu kabul, beyan ve taahhüt
      eder.
      <br />
      <h3>9.5. Fesih</h3>
      İşbu Sözleşme; Kullanıcı'nın üyeliğini iptal etmesine veya Sözleşme
      içerisinde düzenlenen özel haller kapsamında Tezene tarafından üyeliğinin
      iptal edilmesine kadar yürürlükte kalacaktır. Tezene işbu Sözleşme’nin
      herhangi bir hükmünü ihlal etmesi durumunda Kullanıcı'nın üyeliğini iptal
      ederek Sözleşme’yi tek taraflı olarak feshedebilecektir.
      <br />
      <h3>9.6. Yürürlülük</h3>
      Kullanıcı, Tezene uygulamasını mobil cihazına yükleyerek, web sitesi
      üzerinden erişerek, giriş yaparak, bağlanarak veya kullanarak, çerezler
      veya diğer takip teknolojilerinin cihazında kullanılmasına rıza göstererek
      bilgilerinin toplanması ve işlenmesine dair işbu Sözleşme’nin 7.
      Maddesinde yer alan gizlilik politikasında belirtilen şart ve koşulları
      peşinen kabul, beyan ve taahhüt eder. Kullanıcı, iş burada açıklanan
      Sözleşme maddelerinden herhangi birini kabul etmiyorsa, uygulamayı
      yüklememeli, kullanmamalı, uygulamaya erişmemeli ve mobil cihazından
      derhal silmelidir. Kullanıcı'nın, üyelik kaydı yapması, Sözleşme’de yer
      alan tüm maddeleri okuduğu ve bu maddeleri kabul ettiği anlamına
      gelmektedir. İşbu Sözleşme Kullanıcı'nın üye olması anında akdedilmiş ve
      karşılıklı olarak yürürlüğe girmiştir.
      <br />
      <h2>Madde 10 - İletişim</h2>
      Kullanıcı'lar işbu Kullanıcı Sözleşmesi hakkındaki sorular, yorumlar, ve
      talepleri için iletisim@tezene.app e-posta adresinden yetkililer ile
      iletişime geçebilirler.
      <br />
      <br />
      <br />
      <br />
      Son güncelleme tarihi: 23 Şubat 2020
    </div>
  );
}

export default Terms;
