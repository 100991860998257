// src/context/SongManagerContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import { functions, httpsCallable } from "../firebase.config";

const SongManagerContext = createContext();

export const useSongManager = () => useContext(SongManagerContext);

export const SongManagerProvider = ({ children }) => {
  const [songs, setSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchSongs = async () => {
      setIsLoading(true);
      const getSongs = httpsCallable(functions, "songs");
      try {
        const result = await getSongs();
        setSongs(result.data.list);
      } catch (error) {}
      setIsLoading(false);
    };

    fetchSongs();
  }, []);

  return (
    <SongManagerContext.Provider value={{ songs, isLoading }}>
      {children}
    </SongManagerContext.Provider>
  );
};
