import { useSongManager } from "../context/SongManagerContext";
import { RiLoader3Fill } from "react-icons/ri";
import { IoMusicalNotes } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Stores from "../common/stores";

function Home() {
  const { songs, isLoading } = useSongManager();

  return (
    <div className="flex flex-col cursor-default px-8 md:px-10 space-y-10">
      <Helmet>
        <title>Tezene</title>
        <meta
          name="description"
          content="Tezene, günümüz dijital dünyasına uyum sağlamış bağlama öğretmeninizdir."
        />
        <meta
          name="keywords"
          content="bağlama, saz, bağlama çal, saz çal, bağlama öğrenme programı, saz öğrenme programı, iphone, ipad, tezene, türkü, türküler, türkü çalmayı öğren, türkü çal, bağlama çalmayı öğren, saz çalmayı öğren, nasıl bağlama çalınır, bağlama akort, metronom, bağlama metronomu"
        />
      </Helmet>
      <div className="w-full">
        <img
          src="/sari-gelin-all.png"
          alt="Sarı Gelin"
          className="mx-auto"
          style={{ maxHeight: "325px", objectFit: "contain" }}
        />
      </div>
      <div className="bg-orange-50 p-4 rounded-3xl text-center text-sm font-medium space-y-2">
        <p>
          Tezene, günümüz dijital dünyasına uyum sağlamış bağlama
          öğretmeninizdir.
        </p>
        <p>
          iPhone, iPad ve Android uyumlu Tezene'yi indirip kullanmaya
          başlayarak istediğiniz zaman, istediğiniz yerde, istediğiniz hızda
          bağlama çalmayı öğrenebilirsiniz.
        </p>
        <div>
          <Stores />
        </div>
      </div>
      <div className="text-base text-slate-700 space-y-4">
        <h1 className="text-2xl font-medium">Kütüphane</h1>
        <p>
          Bağlama kursuna gitmeye zamanınız mı yok? Her geçen gün genişleyen
          türkü repertuarı ile türküleri kursa gitmenize gerek kalmadan bağlama
          çalabilirsiniz. Tezene'yi indirerek şu türküleri hemen öğrenmeye
          başlayabilirsiniz:
        </p>
        <div>
          {isLoading ? (
            <div className="flex justify-center items-center">
              <RiLoader3Fill className="animate-spin text-4xl" />
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {songs.map((song) => (
                <Link
                  key={song.url}
                  to={`/baglama-cal/${song.url}`}
                  className="flex flex-row justify-leading items-center bg-orange-50 hover:bg-orange-100 p-4 rounded-2xl cursor-pointer space-x-4"
                >
                  <IoMusicalNotes className="text-xl" />
                  <div className="text-base font-semibold">{song.name}</div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="text-slate-700 space-y-4">
        <h1 className="text-2xl font-medium">Egzersizler</h1>
        <p>
          Yeni başlayanlardan ileri seviyede çalanlara kadar herkes için bağlama
          egzersizlerimiz mevcut. Bunun yanı sıra uygulamanın temposunu
          ayarlayarak da dilediğiniz hızda çalışabilir ve uygulama içerisinde
          ayrıca bulunan metronomu çalışmalarınızda kullanarak ritim duygunuzu
          daha da geliştirebilirsiniz.
        </p>
        <p>
          Dahası, Tezene ile nasıl bağlama akort edileceğini öğrenebilirsiniz.
          Şayet bağlanızın akordu tam ise, uygulamanın sesini kendi
          enstrümanınızın sesine eşleyebilir, beraber çalmaya bir an önce
          başlayabilirsiniz.
        </p>
      </div>
    </div>
  );
}

export default Home;
