import { Helmet } from "react-helmet";

function AccountDelete() {
  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <h1>Hesabımı Nasıl Silebirim?</h1>
      <div>
        <p>
          Hesabınızı dilediğiniz zaman, Tezene uygulamasının içinden
          silebilirsiniz. Hesabınızı sildiğinizde, hesabınıza ait tüm veriler
          silinecektir. Bu işlem geri alınamaz.
        </p>
        <p className="my-6">Hesabınızı silmek için:</p>
        <ol class="list-decimal mx-10">
          <li>
            Uygulama navigasyon menüsünden
            <img
              src="/icon-tab-more.svg"
              alt=""
              width={28}
              className="inline"
            />{" "}
            ikonunu seçerek, Ayarlar ekranına ilerleyin.
          </li>
          <li>Hesabım seçeneğini seçin.</li>
          <li>Hesabımı Sil butonuna basın.</li>
          <li>Hesabınızı silme işleminizi onaylayın.</li>
          <li>
            Eğer hesabınızı silmeyi onayladıktan sonra hesap silinemedi hatası
            alıyorsanız, güvenlik sebebiyle, çıkış yapıp tekrar girin ve bu
            adımları tekrarlayın.
          </li>
        </ol>
      </div>
    </div>
  );
}

export default AccountDelete;
