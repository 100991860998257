import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

if (process.env.NODE_ENV === "development") {
  /* eslint-disable-next-line no-restricted-globals */
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}

const firebaseConfig = {
  apiKey: "AIzaSyALmloroYEGEMEMNYl_Nz7Exj07G8Zabz0",
  authDomain: "tezene-10074.firebaseapp.com",
  databaseURL: "https://tezene-10074.firebaseio.com",
  projectId: "tezene-10074",
  storageBucket: "tezene-10074.appspot.com",
  messagingSenderId: "59886941725",
  appId: "1:59886941725:web:6fbcdca0315e99cb6ff8f4",
  measurementId: "G-XMKPDZGHW6",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const functions = getFunctions(app);

// Initialize Firebase App Check
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6LdvOxIqAAAAAFBUTyikB2NXyMWcWl_jwaFHpy1T"
  ),
  isTokenAutoRefreshEnabled: true, // Enable automatic token refresh,
});

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

export { db, appCheck, analytics, functions, httpsCallable };
