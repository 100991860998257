import React, { useEffect, useState } from "react";
import { RiLoader3Fill } from "react-icons/ri";
import { useSongManager } from "../context/SongManagerContext";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

function Song(props) {
  const { songURL } = useParams();
  const { songs, isLoading } = useSongManager();
  const [song, setSong] = useState(null);

  useEffect(() => {
    setSong(songs.find((s) => s.url === songURL));
  }, [songs, props, songURL]);

  var keywords = [];
  if (song) {
    keywords = [
      song.name + " nasıl çalınır",
      song.name + " bağlama notaları",
      song.name + " notaları",
      song.name + " saz notaları",
      song.name + " notası",
      song.name + " solfej",
    ];
  }

  return (
    <div className="flex flex-col px-8 md:px-10 space-y-10">
      <Helmet>
        <title>{song ? "Tezene - " + song.name : "Tezene"}</title>
        <meta
          name="description"
          content={
            song ? song.name + " türküsünü bağlamada çalmayı öğrenin." : ""
          }
        />
        <meta name="keywords" content={keywords.join(", ")} />
      </Helmet>
      {isLoading ? (
        <div className="flex justify-center items-center">
          <RiLoader3Fill className="animate-spin text-4xl" />
        </div>
      ) : (
        <div className="flex flex-col">
          {song && (
            <div className="text-base text-center cursor-default">
              <strong>{song.name}</strong> türküsünü bağlamada çalmayı öğrenmek
              hiç bu kadar kolay olmamıştı. Tezene'yi hemen indirip kullanmaya
              başlayarak istediğiniz zaman, istediğiniz yerde, istediğiniz hızda
              bağlama çalmayı öğrenebilirsiniz.
            </div>
          )}
        </div>
      )}
      <img
        src="/sari-gelin-all.png"
        alt="Sarı Gelin"
        className="mx-auto"
        style={{ maxHeight: "356px", objectFit: "contain" }}
      />
    </div>
  );
}

export default Song;
