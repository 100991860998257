import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import Song from "./pages/Song";
import ScrollToTop from "./ScrollToTop";
import Terms from "./pages/Terms/Terms";
import AccountDelete from "./pages/AccountDelete";
import { useLocation } from "react-router-dom";
import Stores from "./common/stores";

function Navigation() {
  return (
    <div className="flex flex-row items-center bg-gray-50 p-4 rounded-2xl">
      <Link
        to="/"
        className="flex flex-row justify-center items-center space-x-2 cursor-pointer"
      >
        <img src="/logo.png" alt="logo" className="w-12 h-12" />
        <div className="flex flex-col tracking-tight">
          <div className="text-xl font-semibold mt-1.5 leading-4">Tezene</div>
          <div className="text-base text-slate-400 font-medium">
            Bağlama çalmayı öğrenin
          </div>
        </div>
      </Link>
    </div>
  );
}

function Footer(props) {
  let showDownloadAppBanner = props.showDownloadAppBanner || false;
  return (
    <div className="flex flex-col space-y-10">
      {showDownloadAppBanner && (
        <div className="flex flex-col space-y-4 bg-orange-50 p-4 rounded-2xl cursor-default text-center">
          <div className="text-xl md:text-3xl font-semibold">
            Tezene'yi şimdi indirebilirsiniz.
          </div>
          <Stores />
        </div>
      )}
      <div className="flex flex-row justify-center items-center bg-gray-50 p-4 rounded-2xl cursor-default">
        <div className="text-xs font-medium text-slate-400 text-center">
          © {new Date().getFullYear()} Ref Yazılım ve Bilişim Teknolojileri Tic.
          Ltd. Şti. Tüm hakları saklıdır.
        </div>
      </div>
    </div>
  );
}

function App() {
  const location = useLocation();
  const hiddenDownloadButtonRoutes = [
    "/terms.html",
    "/how-to-delete-my-account",
  ];

  return (
    <div className="flex flex-col max-w-5xl align-center mx-auto p-2 md:p-10 space-y-10 text-slate-900">
      <Navigation />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/baglama-cal/:songURL"
          element={<Song item={{ name: "Test" }} />}
        />
        <Route path="/terms.html" element={<Terms />} />
        <Route path="/how-to-delete-my-account" element={<AccountDelete />} />
      </Routes>
      <Footer
        showDownloadAppBanner={
          !hiddenDownloadButtonRoutes.includes(location.pathname)
        }
      />
    </div>
  );
}

export default App;
